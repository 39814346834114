.cm-s-itsjson.unused.CodeMirror {
  background-color: rgb(255, 254, 247);
}
.cm-s-itsjson.CodeMirror {
  background-color: transparent;
}
.cm-s-itsjson span.cm-comment {
  color: #a86;
}
.cm-s-itsjson span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: blue;
}
.cm-s-itsjson span.cm-string {
  color: #1673ff;
}
.cm-s-itsjson span.cm-builtin {
  line-height: 1em;
  font-weight: bold;
  color: #077;
}
.cm-s-itsjson span.cm-special {
  line-height: 1em;
  font-weight: bold;
  color: #0aa;
}
.cm-s-itsjson span.cm-variable {
  color: black;
}
.cm-s-itsjson span.cm-number,
.cm-s-itsjson span.cm-atom {
  color: #3a3;
}
.cm-s-itsjson span.cm-meta {
  color: rgb(255, 254, 247);
}
.cm-s-itsjson span.cm-link {
  color: #3a3;
}
.cm-s-itsjson .CodeMirror-gutters {
  background-color: transparent;
}

.cm-s-itsjson .CodeMirror-activeline-background {
  background-color: transparent;
}
.cm-s-itsjson .CodeMirror-matchingbracket {
  outline: 1px solid rgb(255, 254, 247);
  color: black !important;
}
