.terms-page {
  width: 100%;
  padding-top: 0;
  text-align: left;
}

.terms-container {
  width: 100%;
  padding-top: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 3rem;
  text-align: left;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.terms-container ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.terms-container ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.terms-container ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.terms-container li ol > li {
  margin: 0;
}

.terms-container li ol > li:before {
  content: counters(item, ".") " ";
}

@media screen and (max-width: 1100px) {
  .terms-page {
    padding-left: 0;
    padding-right: 0;
  }

  .terms-container {
    width: 100%;
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
    background: #ffffff;
  }
}
