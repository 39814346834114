.main-page {
  text-align: center;
}

.main-section {
  flex-direction: row;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 800px;
  justify-content: center;
}

.main-section h2 {
  font-size: 60px;
  font-weight: 700;
}

.main-section [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.main-section-text {
  padding: 4rem;
}

.main-section-img img {
  padding: 4.5rem;
  margin: 0;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: 600px;
  max-width: 600px;
}

.main-section [class*="col"] .btn-light {
  height: 60px;
  width: 180px;
  border-radius: 30px;
  border-color: white;
  margin-top: 15px;
  background: white;
  color: black;
  font-weight: 500;
  font-size: large;
  box-shadow: 0 5px 15px rgb(0, 0, 0, 0.2);
}

.main-section [class*="col"] .btn-light:hover {
  height: 60px;
  width: 180px;
  border-radius: 30px;
  border-color: white;
  margin-top: 15px;
  background: whitec;
  color: black;
  font-weight: 700;
  font-size: large;
  box-shadow: 0 5px 15px rgb(0, 0, 0, 0.5);
}

@media screen and (max-width: 1000px) {
  .main-section {
    height: 700px;
  }

  .main-section h2 {
    font-size: 40px;
    font-weight: 700;
  }

  .main-section h4 {
    font-size: 20px;
  }

  .main-section-text {
    padding: 1rem;
  }

  .main-section-img img {
    padding: 0;
    margin: 1rem;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    width: 300px;
  }
}
