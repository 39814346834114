.privacypolicy-page {
  width: 100%;
  padding-top: 0;
  text-align: left;
}

.privacy-policy-container {
  width: 100%;
  padding-top: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 3rem;
  text-align: left;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

@media screen and (max-width: 1100px) {
  .privacypolicy-page {
    padding-left: 0;
    padding-right: 0;
  }

  .privacy-policy-container {
    width: 100%;
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
    background: #ffffff;
  }
}
