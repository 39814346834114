.add-btn {
  min-width: 0px !important;
  width: 30px;
  margin-top: 7px !important;
  padding: 0px;
  border-radius: 20px !important;
}

.atr-val-color-picker-text {
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  background: #fcfaf4;
  margin: 1px 1px 1px 1px;
  padding: 0.25rem 0.5rem;
  font: 400 13.3333px Arial;
  font-size: 0.875rem;
  color: #495057;
  text-align: start;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  line-height: 1.5;
  width: 80px;
}

.atr-val-color-picker-box {
  width: 22px;
  height: 22px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  margin: 5px 0px 0px 1px;
}

.atr-container {
  border: transparent;
  padding: 2px;
  margin: 3px;
  margin-top: 0;

  backdrop-filter: blur(0px) saturate(100%);
  -webkit-backdrop-filter: blur(0px) saturate(100%);
  background-color: transparent;
  border-radius: 12px;
  border: 1px transparent;
}

.atr-container-selected {
  border: 2.5px outset rgb(65, 225, 253);
  border-radius: 10px;
  margin: 3px;
  padding: 2px;
  margin-top: 0;
  backdrop-filter: blur(0px) saturate(100%);
  -webkit-backdrop-filter: blur(0px) saturate(100%);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: 5px 5px 8px rgb(0, 0, 0, 0.5);
}

.atr-add-button button {
  width: 40px;
  backdrop-filter: blur(0px) saturate(100%);
  -webkit-backdrop-filter: blur(0px) saturate(100%);
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  color: #495057;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0;
  padding-bottom: 0.4rem;
  margin: 0.1rem;
  margin-bottom: 0.3rem;
}

.atr-add-button button::after {
  background: transparent;
  color: transparent;
}

.textarea-active {
  display: inline;
  width: 100%;
  margin-top: 5px;
  margin-right: 4px;
  font-size: 15px;
  height: 27px;
  overflow: hidden;
  outline: none; /* Removes the default outline */
}

.textarea-inactive {
  display: inline;
  width: 100%;
  margin-top: 5px;
  margin-right: 4px;
  font-size: 15px;
  height: 27px;
  overflow: hidden;
  background-color: transparent; /* Makes the background transparent */
  border: none; /* Removes the border */
  outline: none; /* Removes the default outline */
}
