.ItemOfFileAndFolder {
  width: 400px;
  border: 1px solid;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 1rem;
  margin-top: 5px;
  margin-left: 12px;
  text-transform: none;
  padding-left: 10px;
}

/* desktop */
@media (min-width: 1224px) {
  .ListOfFileAndFolders {
    gap: 5px;
    margin-top: 15px;
  }
}
/* tablet */
@media (min-width: 1224px) {
  .ListOfFileAndFolders {
    gap: 5px;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .ListOfFileAndFolders {
    gap: 5px;
    margin-top: 5px;
  }
}
/* Context Menu */

.target-light {
  left: 0;
}
.target {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#fff, 0.5);
  font-size: 2vw;
}
.contextMenu {
  --menu-border: rgba(255, 255, 255, 0.08);
  --menu-bg: linear-gradient(45deg, rgba(10, 20, 28, 0.2) 0%, rgba(10, 20, 28, 0.7) 100%);
  --item-border: rgba(255, 255, 255, 0.1);
  --item-color: #fff;
  --item-bg-hover: rgba(255, 255, 255, 0.1);
  height: 0;
  overflow: hidden;
  background: var(--menu-bg);
  backdrop-filter: blur(5px);
  position: fixed;
  top: var(--top);
  left: var(--left);
  animation: menuAnimation 0.4s 0s both;
  transform-origin: left;
  list-style: none;
  margin: 4px;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 999999999;
  box-shadow: 0 0 0 1px var(--menu-border), 0 2px 2px #000, 0 4px 4px #000, 0 10px 8px #000, 0 15px 15px #000, 0 30px 30px #000, 0 70px 65px #000;
}
.contextMenu-item {
  padding: 4px;
}
.contextMenu-item[data-divider="top"] {
  border-top: 1px solid;
}
.contextMenu-item[data-divider="bottom"] {
  border-bottom: 1px solid;
}
.contextMenu-item[data-divider="top-bottom"] {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.contextMenu-item[data-divider] {
  border-color: var(--item-border);
}
.contextMenu-button {
  color: var(--item-color);
  background: 0;
  border: 0;
  white-space: nowrap;
  width: 100%;
  border-radius: 4px;
  padding: 6px 24px 6px 7px;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  animation: menuItemAnimation 0.2s 0s both;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}
.contextMenu-button:hover {
  background-color: var(--item-bg-hover);
}
.contextMenu[data-theme="light"] {
  --menu-bg: linear-gradient(45deg, rgba(255, 255, 255, 0.45) 0%, rgba(255, 255, 255, 0.85) 100%);
  --menu-border: rgba(0, 0, 0, 0.08);
  --item-border: rgba(0, 0, 0, 0.1);
  --item-color: #0a141c;
  --item-bg-hover: rgba(10, 20, 28, 0.09);
}
@keyframes menuAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    height: var(--height);
    opacity: 1;
    border-radius: 8px;
    transform: scale(1);
  }
}
@keyframes menuItemAnimation {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
