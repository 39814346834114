.aboutus-card-img {
  border-radius: 50%;
  border: 7px solid rgb(255, 241, 223);
  width: 150px;
  height: 150px;
  margin-top: 20px;
  margin: 30px auto 20px;
  display: block;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
}

.aboutus-our-team {
  text-align: center;
  padding-top: 2rem;
  color: #555555;
}

.aboutus-dictionary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 100%;
  text-align: center;
  color: #555;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.aboutus-card-name {
  font-size: 1.5rem;
  color: #2ec2b0;
}
.aboutus-card-role {
  font-size: 1.2rem;
}

.aboutus-card {
  margin-top: 10px;
  width: 20rem;

  backdrop-filter: blur(0px) saturate(100%);
  -webkit-backdrop-filter: blur(0px) saturate(100%);
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.aboutus-page {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2rem;
  text-align: left;

  backdrop-filter: blur(0px) saturate(100%);
  -webkit-backdrop-filter: blur(0px) saturate(100%);
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.aboutus-row {
  padding-left: 6rem;
  padding-right: 6rem;
}

.aboutus-container {
  padding-left: 0;
  padding-right: 0;
}

.aboutus-title {
  padding-right: 4rem;
  color: #555555;
  font: 50px Poppins, sans-serif;
}

.aboutus-description {
  color: #555555;
  font: 36px Poppins, sans-serif;
}

.aboutus-img {
  height: auto;
  max-height: 400px;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .aboutus-row {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .aboutus-title {
    padding-right: 1rem;
    font: 36px Poppins, sans-serif;
  }

  .aboutus-description {
    font: 24px Poppins, sans-serif;
  }

  .aboutus-img {
    height: 40vh;
    object-fit: cover;
  }
}
