.contactus-page {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 2rem;
  text-align: left;

  backdrop-filter: blur(0px) saturate(100%);
  -webkit-backdrop-filter: blur(0px) saturate(100%);
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.contactus-dictionary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 100%;
  text-align: center;
  color: #555;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}

.contactus-row {
  padding-left: 6rem;
  padding-right: 6rem;
}

.contactus-container {
  padding-left: 0;
  padding-right: 0;
}

.contactus-title {
  padding-right: 4rem;
  color: #555555;
  font: 50px Poppins, sans-serif;
}

.contactus-description {
  color: #555555;
  font: 36px Poppins, sans-serif;
}

.contactus-img {
  height: auto;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .contactus-row {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .contactus-title {
    padding-right: 1rem;
    font: 36px Poppins, sans-serif;
  }

  .contactus-description {
    font: 24px Poppins, sans-serif;
  }

  .contactus-img {
    height: 40vh;
    object-fit: cover;
  }
}
